import { createContext, useContext } from 'react'

export const InstallationContext = createContext<{
  id: string
  installations: {
    [id: string]: {
      rewardsEnabled: boolean
      points: number
      birthdayPoints: number
      anniversaryPoints: number
      userCount: number
    }
  }
  role: 'admin' | 'member'
  set: (id: string) => void
  setRole: (role: 'admin' | 'member') => void
  setInstallations: (installations: {
    [id: string]: {
      rewardsEnabled: boolean
      points: number
      birthdayPoints: number
      anniversaryPoints: number
      userCount: number
    }
  }) => void
}>({
  id: '',
  installations: {},
  role: 'member',
  set: () => {},
  setRole: () => {},
  setInstallations: () => {}
})

export const useInstallationContext = () => {
  const ctx = useContext(InstallationContext)
  // if (!ctx) { throw new Error(`${AuthContext.displayName} context not found`) }
  return ctx
}
