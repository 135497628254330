import { Redirect, Route } from 'react-router-dom'
import { useAuthContext } from '../AuthContext/AuthContext'

export const AuthRoute = (props: any) => {
  const user = useAuthContext()
  if (user?.id) {
    return <Route path={props.to}>{props.children}</Route>
  }
  return <Redirect to="/login" />
}
