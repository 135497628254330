import { Table, Loader, Avatar, Pagination } from '@mantine/core'
import { useInstallationContext } from '../InstallationContext/InstallationContext'
import useAxios from 'axios-hooks'
import { useState } from 'react'
import { SPagination } from '../common/Pagination'

type Member = {
  id: string
  createdAt: string
  imageUrl: string
  displayName: string
  role: 'member' | 'admin'
}

const pageSize = 10

export const Members = () => {
  const ic = useInstallationContext()
  const installationId = ic?.id!

  const [activePage, setPage] = useState(0)

  const [{ data, loading: membersLoading }] = useAxios<{
    data: Member[]
    total: number
  }>({
    url: `installations/${installationId}/members`,
    params: {
      page: activePage,
      pageSize
    }
  })

  const members = data?.data
  const total = data?.total

  if (!installationId || membersLoading) {
    return (
      <div
        style={{ height: 200, width: '100%', transform: 'translate(50%, 50%)' }}
      >
        <Loader />
      </div>
    )
  }

  return (
    <>
      <Table withBorder style={{ backgroundColor: 'white' }}>
        <thead>
          <tr>
            <th>Teammate</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {members?.map((a) => (
            <tr key={a.id}>
              <td style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar src={a.imageUrl} />
                <div style={{ paddingLeft: 20 }}>{a.displayName}</div>
              </td>
              <td>{a.role}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <SPagination
        activePage={activePage}
        setPage={setPage}
        total={total}
        pageSize={pageSize}
      />
    </>
  )
}
