import axios from 'axios'
import { slashsendUrl } from './config'
import { fbAuth } from './firebase'
import { configure } from 'axios-hooks'

export const ssendClient = axios.create({
  baseURL: `${slashsendUrl}v1/`
})

ssendClient.interceptors.request.use(async (config) => {
  try {
    const token = await fbAuth.currentUser?.getIdTokenResult()
    if (token) {
      const authorization = config.headers?.Authorization
      return {
        ...config,
        headers: {
          ...config.headers,
          ...(!authorization ? { authorization: token.token } : {})
        }
      }
    }
  } catch (err) {
    return config
  }
  return config
})

configure({
  axios: ssendClient
})
