import { Pagination } from '@mantine/core'

export const SPagination = ({
  activePage,
  setPage,
  total,
  pageSize
}: {
  activePage: number
  setPage: (n: number) => void
  total?: number
  pageSize: number
}) => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 80,
      paddingBottom: 80
    }}
  >
    <Pagination
      value={activePage + 1}
      onChange={(n) => setPage(n - 1)}
      total={Math.ceil((total || 0) / pageSize)}
    />
  </div>
)
