import { useEffect, useState } from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import { useAuthContext } from '../AuthContext/AuthContext'
import { fbAuth } from '../firebase'
import { ssendClient } from '../ssendClient'
import { Timeline } from '../Timeline/Timeline'
import { TopBar } from '../TopBar/TopBar'
import './Home.css'
import { useInstallationContext } from '../InstallationContext/InstallationContext'
import { Members } from '../Members/Members'
import { Button, Loader, Modal, Select, Tooltip } from '@mantine/core'
import { Analytics } from '../Analytics/Analytics'
import { AdminPanel } from '../AdminPanel/AdminPanel'
import { RewardPage } from '../RewardPage/RewardPage'
import { RewardHistoryPage } from '../RewardHistoryPage/RewardHistoryPage'

export const Home = () => {
  const authContext = useAuthContext()
  const [signoutOpen, setSignoutOpen] = useState(false)

  const installationContext = useInstallationContext()

  const [installationsLoading, setInstallationsLoading] = useState(true)
  const [installations, setInstallations] = useState<
    | undefined
    | { id: string; name: string; teamId?: string; role: 'member' | 'admin' }[]
  >()

  useEffect(() => {
    const doCall = async () => {
      const result = await fbAuth.currentUser?.getIdTokenResult()
      if (result) {
        ssendClient
          .get('account/installations', {
            headers: {
              authorization: result.token
            }
          })
          .then((x) => {
            setInstallations(x.data.data)
            if (x.data.data.length > 0) {
              installationContext.set(x.data.data[0].id)
              installationContext.setInstallations(
                x.data.data.reduce(
                  (acc: any, curr: any) => ({
                    ...acc,
                    [curr.id]: {
                      rewardsEnabled: curr.rewardsEnabled,
                      points: curr.points,
                      birthdayPoints: curr.birthdayPoints,
                      anniversaryPoints: curr.anniversaryPoints,
                      userCount: curr.userCount
                    }
                  }),
                  {}
                )
              )
              installationContext.setRole(x.data.data[0].role)
            }
            setInstallationsLoading(false)
          })
          .catch((e) => console.log(e))
      }
    }
    doCall()
  }, [])

  const { rewardsEnabled } =
    installationContext?.installations?.[installationContext.id] || {}

  return (
    <Container fluid>
      <Row>
        <Col
          md={3}
          style={{
            backgroundColor: 'white'
          }}
        >
          <TopBar />
        </Col>
      </Row>
      <Row>
        <Col
          md={3}
          className="d-none d-md-block"
          style={{
            backgroundColor: 'white',
            minHeight: 'calc(100vh - 46px)',
            maxHeight: '100vh'
          }}
        >
          {installationsLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}><Loader /></div>
          ) : installations && installations.length > 0 ? (
            <Select
              onChange={(v) => {
                installationContext.set(v!)
                const role =
                  installations.find((a) => a.id === v)?.role ?? 'member'
                installationContext.setRole(role)
              }}
              defaultValue={installations[0].id}
              data={installations.map((a) => ({ value: a.id, label: a.name }))}
            />
          ) : null}
          <Nav defaultActiveKey="/home/timeline" className="flex-column">
            <NavLink
              to="/home/timeline"
              style={(isActive) => ({
                color: isActive ? 'rgb(111, 168, 93)' : 'black',
                paddingLeft: 15,
                paddingTop: 10
              })}
            >
              Home
            </NavLink>
            <NavLink
              to="/home/members"
              style={(isActive) => ({
                color: isActive ? 'rgb(111, 168, 93)' : 'black',
                paddingLeft: 15,
                paddingTop: 10
              })}
            >
              Members
            </NavLink>
            {rewardsEnabled ? (
              <>
                <NavLink
                  to="/home/rewards"
                  style={(isActive) => ({
                    color: isActive ? 'rgb(111, 168, 93)' : 'black',
                    paddingLeft: 15,
                    paddingTop: 10
                  })}
                >
                  Redeem Reward Points
                </NavLink>
                <NavLink
                  to="/home/rewards-history"
                  style={(isActive) => ({
                    color: isActive ? 'rgb(111, 168, 93)' : 'black',
                    paddingLeft: 15,
                    paddingTop: 10
                  })}
                >
                  Rewards History
                </NavLink>
              </>
            ) : (
              <Tooltip
                style={{ marginLeft: -150 }}
                position="right"
                label="Not enabled"
              >
                <NavLink
                  to="/home/rewards"
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                  style={() => ({
                    color: 'grey',
                    paddingLeft: 15,
                    paddingTop: 10
                  })}
                >
                  Rewards
                </NavLink>
              </Tooltip>
            )}
            <NavLink
              to="/home/analytics"
              style={(isActive) => ({
                color: isActive ? 'rgb(111, 168, 93)' : 'black',
                paddingLeft: 15,
                paddingTop: 10
              })}
            >
              Analytics
            </NavLink>
            {installationContext?.role === 'admin' ? (
              <NavLink
                to="/home/team-admin"
                style={(isActive) => ({
                  color: isActive ? 'rgb(111, 168, 93)' : 'black',
                  paddingLeft: 15,
                  paddingTop: 10
                })}
              >
                Team admin
              </NavLink>
            ) : null}
            <Modal
              opened={signoutOpen}
              onClose={() => setSignoutOpen(false)}
              title="Sign out"
            >
              <p>Are you sure you want to sign out?</p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  color="gray"
                  onClick={() => {
                    setSignoutOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    authContext?.signOut()
                  }}
                >
                  Sign out
                </Button>
              </div>
            </Modal>
            <Nav.Link
              style={{ color: 'black' }}
              eventKey="link-1"
              onClick={() => {
                setSignoutOpen(true)
              }}
            >
              Sign out
            </Nav.Link>
          </Nav>
        </Col>
        <Col
          md={9}
          style={{
            maxHeight: '100vh',
            overflowY: 'auto',
            marginTop: -57,
            paddingTop: 57,
            paddingRight: 20,
            paddingLeft: 20
          }}
        >
          <Switch>
            <Route path="/home/timeline" exact>
              <Timeline
                installationsLoading={installationsLoading}
                installationId={installationContext?.id}
              />
            </Route>
            <Route path="/home/members" exact>
              <Members />
            </Route>
            <Route path="/home/rewards">
              <RewardPage />
            </Route>
            <Route path="/home/rewards-history">
              <RewardHistoryPage />
            </Route>
            <Route path="/home/team-admin">
              <AdminPanel />
            </Route>
            <Route path="/home/analytics">
              {installationContext.id && <Analytics />}
            </Route>
            <Route path="/home">
              <Redirect to="/home/timeline" />
            </Route>
          </Switch>
        </Col>
      </Row>
    </Container>
  )
}
