import React, { useEffect, useState } from 'react'
import { Alert, Image, Spinner } from 'react-bootstrap'
import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithCustomToken
} from 'firebase/auth'
import { fbAuth } from '../firebase'
import { InputGroup, Form, Button } from 'react-bootstrap'
import { GoogleSignInButton } from '../GoogleSignInButton'
import { FirebaseError } from 'firebase/app'
import background from '../backgroundslash.png'
import { ssendClient } from '../ssendClient'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { truncateSync } from 'fs'
import { useAuthContext } from '../AuthContext/AuthContext'
import './Login.css'
import { slashsendUrl } from '../config'

const provider = new GoogleAuthProvider()

export const Login = () => {
  const { push } = useHistory()

  const user = useAuthContext()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [validationError, setValidationError] = useState('')

  useEffect(() => {
    const params = new URL(window.location.href).searchParams
    const accessToken = params.get('accessToken')

    if (accessToken) {
      signInWithCustomToken(fbAuth, accessToken)
        .then(() => {
          push('home')
        })
        .catch(() => {
          push('login')
          setValidationError('something went wrong')
        })
    }
  }, [])

  // useEffect(() => {
  //   if (user?.id) {
  //     push('home')
  //   }
  // }, [])

  // useEffect(() => {
  //   const unsubscribe = fbAuth.onAuthStateChanged(user => {
  //     if (user) {
  //       push('home')
  //     }
  //   })
  //   return () => unsubscribe()
  // }, [])

  return (
    <>
      <Image
        style={{ height: '60vh', width: '100%', position: 'absolute' }}
        src={background}
      />
      <div
        style={{
          height: '65vh',
          width: '100%',
          backgroundColor: 'rgba(235,252,232, 0.5)',
          position: 'absolute'
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          paddingTop: '30vh'
        }}
      >
        <div
          className="form-middle"
          style={{ textAlign: 'center', transform: 'translateY(100px)' }}
        >
          <div className="form-middle-inner">
            <h1
              style={{
                fontWeight: 700,
                marginLeft: -40,
                fontFamily: "'Source Code Pro', monospace"
              }}
            >
              /slashSend
            </h1>
            <p
              style={{
                fontFamily: "'Source Code Pro', monospace"
              }}
            >
              Send Feedback and Rewards.<strong> Via Slack.</strong>
              <br />
              <strong>/Send 💖🎁👏🎉</strong>
            </p>
            {/* <Form.Control
          placeholder="email"
          value={username}
          type="email"
          onChange={(e: any) => setUsername(e.target.value)}
        />
        <Form.Control
          style={{ marginTop: 10 }}
          placeholder="password"
          type="password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <Button
          style={{ marginTop: 20, marginBottom: 20, width: '100%', fontWeight: 'bold', fontSize: 16 }}
          onClick={(e: any) => {
            e.preventDefault()
            login()
          }}
        >Sign in</Button>
        */}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <a
              href={`${slashsendUrl}v1/oauth/slack-sign-in`}
              className="slack-sign-in"
              style={{
                display: 'flex',
                marginTop: '10px',
                marginBottom: '10px',
                alignItems: 'center',
                color: '#000',
                backgroundColor: '#fff',
                borderRadius: '4px',
                fontFamily: 'Lato, sans-serif',
                fontSize: '14px',
                fontWeight: 600,
                height: '35px',
                justifyContent: 'center',
                textDecoration: 'none',
                width: '200px'
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ height: '35px', width: '20px', marginRight: '12px' }}
                viewBox="0 0 122.8 122.8"
              >
                <path
                  d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                  fill="#e01e5a"
                ></path>
                <path
                  d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                  fill="#36c5f0"
                ></path>
                <path
                  d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                  fill="#2eb67d"
                ></path>
                <path
                  d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                  fill="#ecb22e"
                ></path>
              </svg>
              Sign in with Slack
            </a>
            <hr
              style={{
                height: 2,
                color: 'green',
                width: 260,
                margin: 0,
                marginLeft: -30
              }}
            />
            {/* <GoogleSignInButton
      onClick={async () => {
        try {
        const { user } = await signInWithPopup(fbAuth, provider)
        const token = await user.getIdToken()
        await ssendClient.post('signup', {}, {
          headers: {
            authorization: token
          }
        })
        push('/home')
        } catch (e) {
          setValidationError('failed to login')
        }
      }}
    /> */}
            <Button
              href="https://slack.com/oauth/v2/authorize?scope=chat%3Awrite%2Cchat%3Awrite.public%2Ccommands%2Cincoming-webhook%2Cusers%3Aread%2Cusers%3Aread.email&client_id=2544464690679.2552641217286"
              variant="secondary"
              target="_blank"
              style={{
                width: 200,
                fontSize: 15,
                marginTop: 10,
                display: 'flex',
                justifyContent: 'space-around'
              }}
              className="learn-ssend"
            >
              Install on Slack
            </Button>
            <div style={{ marginTop: 10, position: 'absolute', bottom: -80 }}>
              {validationError && (
                <Alert variant="warning">{validationError}</Alert>
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: 'absolute', bottom: 5, left: 10 }}>
        <Link to="/terms-of-service">Terms of service</Link>
        &nbsp;&nbsp;&nbsp;
        <Link to="/privacy-policy">Privacy policy</Link>
      </div>
      <div style={{ position: 'absolute', bottom: 5, right: 10 }}>
        slashSend © {new Date().getFullYear()}
      </div>
    </>
  )
}
