import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import logo from './backgroundslash.png'
import grandad from './grandad.jpg'
import './App.css'
// import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js'
import { CheckoutForm } from './Payment/Payment'
import { Route, Switch, useHistory, useLocation } from 'react-router'
import { Card, Button, Container, Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Home } from './Home/Home'
import { Login } from './Login/Login'
import { AuthContainer } from './AuthContext/AuthContainer'
import { AuthRoute } from './common/AuthRoute'
import { slashsendUrl } from './config'
import { Privacy } from './Privacy/Privacy'
import { Terms } from './Terms/Terms'

const STRIPE_PKEY = process.env.REACT_APP_STRIPE_PKEY
// @ts-ignore
const stripe = Stripe(STRIPE_PKEY)

const Perky = () => {
  const [token, setToken] = useState<string>('')
  const history = useHistory()
  return (
    <Elements stripe={stripe}>
      <CheckoutForm token={token} />
    </Elements>
  )
}

const App = () => (
  <Switch>
    <Route path="/login">
      <Login />
    </Route>
    <AuthRoute path="/home">
      <Home />
    </AuthRoute>
    <Route path="/payment">
      <Container>
        <Row className="justify-content-md-center">
          <Col className="col-md-8">
            <Card className="text-center" style={{ marginTop: 50 }}>
              <Card.Img
                variant="top"
                src={logo}
                width="500"
                style={{ height: 200, objectFit: 'cover' }}
              />
              <Card.Body>
                <Perky />
              </Card.Body>
              <Card.Footer className="text-muted">
                slashSend - {new Date().getFullYear()}{' '}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </Route>
    <Route path="/success">
      <Container>
        <Row className="justify-content-md-center">
          <Col className="col-md-8">
            <Card className="text-center" style={{ marginTop: 50 }}>
              <Card.Img
                variant="top"
                src={logo}
                width="500"
                style={{ height: 400, objectFit: 'cover' }}
              />
              <Card.Body>
                <Card.Title>Payment succeeded</Card.Title>
                <Card.Text>
                  You will receive a receipt and your reward will be delivered
                  shortly.
                </Card.Text>
                <Button href="https://slashsend.io/" variant="primary">
                  Learn about slashSend
                </Button>
              </Card.Body>
              <Card.Footer className="text-muted">
                slashSend - {new Date().getFullYear()}{' '}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </Route>
    <Route path="/failed">
      <Container>
        <Row className="justify-content-md-center">
          <Col className="col-md-8">
            <Card className="text-center" style={{ marginTop: 50 }}>
              <Card.Img
                variant="top"
                src={grandad}
                width="500"
                style={{ height: 400, objectFit: 'cover' }}
              />
              <Card.Body>
                <Card.Title>Payment failed </Card.Title>
                <Card.Text>
                  Hmm oops that didn't work. Have another go at rewarding your
                  teammate!
                </Card.Text>
                <Button href="https://slashsend.io/" variant="primary">
                  Learn about slashSend
                </Button>
              </Card.Body>
              <Card.Footer className="text-muted">
                slashSend - {new Date().getFullYear()}{' '}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </Route>
    <Route path="/install-error">
      <div
        style={{
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <img src={grandad} style={{ height: 200 }} />
        <div>
          <code>Something went wrong during installation</code>
          <p>
            Please try again, meanwhile we will try and figure out the issue
          </p>
          <p>Thank you for your patience 🙏</p>
        </div>
      </div>
    </Route>
    <Route path="/get-started">
      <Container>
        <Row className="justify-content-sm-center">
          <Col className="col-sm-8">
            <Card className="text-center" style={{ marginTop: 50 }}>
              <Card.Img
                variant="top"
                src={logo}
                width="500"
                style={{ height: 200, objectFit: 'cover' }}
              />
              <Card.Body>
                <Card.Title>Success!</Card.Title>
                <Card.Text>
                  You and your teammates can start sending eachother positive
                  vibes
                </Card.Text>
                <Card.Text>
                  Step 1: type <code>/kudos</code> in your slashSend channel
                </Card.Text>
                <Card.Text>Step 2: send them your positive feedback</Card.Text>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <a
                    href={`${slashsendUrl}v1/oauth/slack-sign-in`}
                    className="slack-sign-in"
                    style={{
                      display: 'block',
                      border: '1px solid lightgrey',
                      marginTop: '10px',
                      marginBottom: '10px',
                      alignItems: 'center',
                      color: '#000',
                      backgroundColor: '#fff',
                      borderRadius: '4px',
                      fontFamily: 'Lato, sans-serif',
                      fontSize: '14px',
                      fontWeight: 600,
                      height: '35px',
                      justifyContent: 'center',
                      textDecoration: 'none',
                      width: '200px'
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        height: '35px',
                        width: '20px',
                        marginRight: '12px'
                      }}
                      viewBox="0 0 122.8 122.8"
                    >
                      <path
                        d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                        fill="#e01e5a"
                      ></path>
                      <path
                        d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                        fill="#36c5f0"
                      ></path>
                      <path
                        d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                        fill="#2eb67d"
                      ></path>
                      <path
                        d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                        fill="#ecb22e"
                      ></path>
                    </svg>
                    Sign in with Slack
                  </a>
                </div>
              </Card.Body>
              <Card.Footer className="text-muted">
                <a href="https://slashsend.io/">slashSend</a> -{' '}
                {new Date().getFullYear()}{' '}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </Route>
    <Route path="/privacy-policy">
      <Privacy />
    </Route>
    <Route path="/terms-of-service">
      <Terms />
    </Route>
    <Route path="">
      <Redirect to="home" />
    </Route>
    <Route path="*">
      <div
        style={{
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-100px, -100px)'
        }}
      >
        <img src={grandad} style={{ height: 200 }} />
        <div>
          <code>404 - Page not found</code>
          <br />
          <Link to="/home">Take me home</Link>
        </div>
      </div>
    </Route>
  </Switch>
)

export default App
