import { Link } from "react-router-dom"

export const Privacy = () => (
  <div style={{ margin: 50, maxWidth: 800 }}>

    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw'
    }}>
      <Link to="/login">
        <h2>
          Return to home
        </h2>
      </Link>
    </div>

    <h1>Privacy Policy</h1>

    <p><strong>Effective Date:</strong> 2023/12/30</p>

    <p>Your privacy is important to us. It is slashSend's policy to respect your privacy regarding any information we may collect from you through our Slack app ("the App"), our website, slashsend.io, and other platforms we own and operate.</p>

    <h2>1. Information We Collect</h2>

    <p>We only ask for personal information when we truly need it to provide a seamless service to you. Specifically, we request your name within the App to facilitate the sending and receiving of kudos and rewards among employees. This information enables us to provide a personalized experience and enhance communication within your team.</p>

    <p>We collect this information by fair and lawful means, with your knowledge and consent. We explicitly inform you about the purpose of collecting your name within the App and how it will be utilized.</p>

    <h2>2. Data Retention and Security</h2>

    <p>We retain collected information only for as long as necessary to provide you with the requested service. The data we store is protected within commercially acceptable means to prevent loss, theft, unauthorized access, disclosure, copying, use, or modification.</p>

    <h2>3. Information Sharing</h2>

    <p>We do not share any personally identifying information publicly or with third parties, except when required to do so by law.</p>

    <h2>4. External Links</h2>

    <p>Our App and website may contain links to external sites not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot accept responsibility or liability for their respective privacy policies.</p>

    <h2>5. User Choice</h2>

    <p>You are free to refuse our request for your personal information, with the understanding that it may impact our ability to provide you with some desired services within the App.</p>

    <h2>6. Acceptance of Terms</h2>

    <p>Your continued use of our App and website will be regarded as acceptance of our practices concerning privacy and personal information.</p>

    <h2>7. Contact Us</h2>

    <p>If you have any questions about how we handle user data and personal information, or if you would like to inquire about or request the deletion of your information, please feel free to contact us at team@slashsend.io.</p>

    <h2>8. Changes to This Policy</h2>

    <p>This policy is effective as of 2023/12/30. We reserve the right to update or change our privacy policy at any time. Any changes will be posted on this page with a revised effective date.</p>

    <p>We appreciate your trust in slashSend and assure you that we are committed to safeguarding your privacy.</p>

  </div>
)
