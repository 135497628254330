import { User } from 'firebase/auth'
import { createContext, useContext } from 'react'

export const AuthContext = createContext<
  { signOut: () => void; id: string } | undefined
>(undefined)

export const useAuthContext = () => {
  const ctx = useContext(AuthContext)
  // if (!ctx) { throw new Error(`${AuthContext.displayName} context not found`) }
  return ctx
}
