import {
  Avatar,
  Card,
  Col,
  Divider,
  Grid,
  Loader,
  NativeSelect,
  Select,
  Table
} from '@mantine/core'
import useAxios from 'axios-hooks'
import { useInstallationContext } from '../InstallationContext/InstallationContext'
import { useState } from 'react'
import dayjs from 'dayjs'
import { ResponsiveBar } from '@nivo/bar'

type FeedbackTypeKey =
  | 'amazing_job'
  | 'kind_candid'
  | 'lemon_twist'
  | 'null'
  | 'thank_you'
  | 'well_done'
  | 'legend'
  | 'impressive'

type FeedbackAnalytics = {
  feedback: {
    totalCount: number
    typeAndCount: Record<
      FeedbackTypeKey,
      {
        count: number
        title: string
      }
    >
  }
}

const xDate = () => dayjs().day(-2).hour(16).minute(30)

const dates = [...new Array(52)].reduce(
  (acc, _, i) => {
    if (i === 0) {
      acc['current_week'] = {
        start: xDate().toISOString(),
        end: dayjs().toISOString()
      }
    } else {
      acc[`week_${i}`] = {
        start: xDate().subtract(i, 'week').toISOString(),
        end: xDate()
          .subtract(i - 1, 'week')
          .toISOString()
      }
    }
    return acc
  },
  {} as Record<string, { start: string; end: string }>
)

const labels = [...new Array(52)].map((_, i) => {
  if (i === 0) {
    return {
      value: 'current_week',
      label: 'This week'
    }
  }
  if (i === 1) {
    return {
      value: 'week_1',
      label: 'Last week'
    }
  }
  return {
    value: `week_${i}`,
    label: `${i} weeks ago`
  }
})

export const Analytics = () => {
  const [params, setParams] = useState<{ start: string; end: string }>({
    start: dates.current_week.start,
    end: dates.current_week.end
  })

  const { id } = useInstallationContext()

  const [sortBy, setSortBy] = useState('feedbackgiven')

  const [{ data, loading }] = useAxios<{
    data: FeedbackAnalytics
    total: number
  }>({
    url: `installations/${id}/analytics`,
    params
  })

  const [{ data: data2, loading: loading2 }] = useAxios<{
    data: FeedbackAnalytics
    total: number
  }>({
    url: `installations/${id}/analytics`
  })

  const [{ data: data3, loading: loading3 }] = useAxios<{
    data: any
    total: number
  }>({
    url: `installations/${id}/user-analytics`
  })

  if (loading2 || loading3) {
    return <Loader />
  }

  return (
    <Card style={{ padding: 30 }}>
      <h4>Analytics</h4>
      <h5>All time</h5>
      <Divider my="xs" size="sm" />
      <Grid>
        <Col
          span={4}
          style={{
            height: 300,
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <h1>{data2?.data.feedback.totalCount}</h1>
          <div>total feedback</div>
        </Col>
        <Col span={8} style={{ height: 300 }}>
          <ResponsiveBar
            data={Object.entries(data2?.data.feedback.typeAndCount ?? {}).map(
              ([key, value]) => ({
                id: value.title,
                count: value.count,
                title: value.title
              })
            )}
            // enableLabel={false}
            label={(e) => `${e.data.count}`}
            // keys={Object.keys(data?.data.feedback.typeAndCount ?? {})}
            keys={['count']}
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'paired' }}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: 32
            }}
            legendLabel={(e) => e?.data?.title || ''}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Count',
              legendPosition: 'middle',
              legendOffset: -40,
              format: (e) => (Math.floor(e) === e && e) || ''
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]]
            }}
            role="application"
          />
        </Col>
        <Col span={12}>
          <Divider my="xs" size="sm" />
        </Col>
        <Col span={4}>
          <Select
            disabled={loading}
            defaultValue="current_week"
            onChange={(e) => {
              if (!e) return
              setParams(dates[e])
            }}
            label="Select week"
            description="Statistics for the selected week are displayed"
            searchable
            data={labels}
          />
          {/* <DatePickerInput
        type="range"
        label="Select date range"
        placeholder="Select date range"
        value={value}
        onChange={setValue}
        mx="auto"
        maw={400}
      /> */}
          {/* <h5>Feedback</h5> */}
        </Col>
      </Grid>
      <Grid>
        {loading ? (
          <Col
            span={12}
            style={{
              height: 300,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <Loader />
          </Col>
        ) : data?.data.feedback.totalCount === 0 ? (
          <Col
            span={12}
            style={{
              height: 300,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <h5
              style={{
                color: 'gray'
              }}
            >
              No kudos this week
            </h5>
          </Col>
        ) : (
          <>
            <Col
              span={4}
              style={{
                height: 300,
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              <h1>{data?.data.feedback.totalCount}</h1>
              <div>total feedback</div>
            </Col>
            <Col span={8} style={{ height: 300 }}>
              <ResponsiveBar
                data={Object.entries(
                  data?.data.feedback.typeAndCount ?? {}
                ).map(([key, value]) => ({
                  id: value.title,
                  count: value.count,
                  title: value.title
                }))}
                // enableLabel={false}
                label={(e) => `${e.data.count}`}
                // keys={Object.keys(data?.data.feedback.typeAndCount ?? {})}
                keys={['count']}
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'paired' }}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legendPosition: 'middle',
                  legendOffset: 32
                }}
                legendLabel={(e) => e?.data?.title || ''}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Count',
                  legendPosition: 'middle',
                  legendOffset: -40,
                  format: (e) => (Math.floor(e) === e && e) || ''
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]]
                }}
                role="application"
              />
              {/* <ResponsiveBar
            data={
              Object.entries(data?.data.feedback.typeAndCount ?? {}).map(([key, value]) => ({
                id: key,
                count: value
              }))
            }
          /> */}
            </Col>
          </>
        )}

        <Col span={12}>
          <Divider my="xs" size="sm" />
          <h5>Leaderboard</h5>
        </Col>
        <Select
          defaultValue={'feedbackgiven'}
          onChange={(e) => {
            if (!e) return
            setSortBy(e)
          }}
          label="Sort"
          data={[
            { value: 'feedbackgiven', label: 'Feedback given' },
            { value: 'feedbackreceived', label: 'Feedback received' }
          ]}
        />
        <Col span={12}>
          <Table withBorder style={{ backgroundColor: 'white' }}>
            <thead>
              <tr>
                <th>Teammate</th>
                <th>Name</th>
                <th>Kudos given</th>
                <th>Kudos received</th>
              </tr>
            </thead>
            <tbody>
              {data3?.data
                .sort((a: any, b: any) => (a[sortBy] > b[sortBy] ? -1 : 1))
                .map((a: any) => (
                  <tr key={a.id}>
                    <td style={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar src={a.imageUrl} />
                    </td>
                    <td>{a.name}</td>
                    <td>{a.feedbackgiven}</td>
                    <td>{a.feedbackreceived}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Grid>
    </Card>
  )
}
