import useAxios from 'axios-hooks'
import { useInstallationContext } from '../InstallationContext/InstallationContext'
import { Loader, Table } from '@mantine/core'
import dayjs from 'dayjs'

type RewardOrder = {
  createdAt: string
  points: number
  data: {
    description: string
  }
}

export const RewardHistoryPage = () => {
  const installationContext = useInstallationContext()

  const [{ loading, data }] = useAxios<{ data: RewardOrder[] }>(
    {
      url: `account/installations/${installationContext?.id}/rewards/orders`,
      data: {
        installationId: installationContext?.id
      },
      method: 'GET'
    },
    {
      manual: installationContext?.id ? false : true
    }
  )

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <Loader />
      </div>
    )
  }

  return (
    <div>
      <h1>Reward History</h1>
      <Table withBorder style={{ backgroundColor: 'white' }}>
        <thead>
          <tr>
            <th>Reward</th>
            <th>Points used</th>
            <th>Redeemed</th>
          </tr>
        </thead>
        <tbody>
          {data?.data.map((order) => (
            <tr>
              <td>{order.data.description}</td>
              <td>{order.points}</td>
              <td>{dayjs(order.createdAt).format('DD-MM-YYYY')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
