import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react"
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import { AuthContainer } from './AuthContext/AuthContainer'
import { InstallationContainer } from './InstallationContext/InstallationContainer'
import { MantineProvider } from '@mantine/core'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [],
  })
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContainer>
        <InstallationContainer>
          <MantineProvider
            theme={{
              fontFamily: "'Source Code Pro', monospace"
            }}
          >
            <App />
          </MantineProvider>
        </InstallationContainer>
      </AuthContainer>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
