import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { fbAuth } from '../firebase'
import { ssendClient } from '../ssendClient'
import { AuthContext } from './AuthContext'
import { useAuthState } from 'react-firebase-hooks/auth'
import { signInWithCustomToken } from 'firebase/auth'
import { Loader } from '@mantine/core'

export const AuthContainer = ({ children }: { children: React.ReactNode }) => {
  const [authCtxUser, setAuthCtxUser] = useState<any>(undefined)
  const [user, authLoading, error] = useAuthState(fbAuth)
  const [loading, setLoading] = useState(true)
  const [authTokenLoading, setAuthTokenLoading] = useState(true)
  const { push } = useHistory()

  useEffect(() => {
    const params = new URL(window.location.href).searchParams
    const accessToken = params.get('accessToken')
    if (accessToken) {
      signInWithCustomToken(fbAuth, accessToken)
        .then(() => {
          setAuthTokenLoading(false)
          push('home')
        })
        .catch(() => {
          setAuthTokenLoading(false)
          push('login')
        })
    } else {
      setAuthTokenLoading(false)
    }
  }, [])

  useEffect(() => {
    const x = async () => {
      setLoading(true)
      if (user) {
        const token = await user.getIdToken()
        const response = await ssendClient
          .get('account/user', {
            headers: {
              authorization: token
            }
          })
          .catch((e) => {
            console.log(e)
          })
        setAuthCtxUser(response?.data?.data)
        setLoading(false)
      } else {
        setAuthCtxUser(undefined)
        setLoading(false)
      }
    }
    if (!authCtxUser && !authLoading) {
      x()
    }
  }, [user, authCtxUser, authLoading])

  const signOut = async () => {
    setLoading(true)
    try {
      await fbAuth.signOut()
    } catch (e) {
      setLoading(false)
    }
    setAuthCtxUser(undefined)
    setLoading(false)
    push('/login')
  }

  if (authLoading || loading || authTokenLoading) {
    return (
      <>
        {/* <div style={{ height: '50vh', width: '100%', backgroundColor: 'rgba(235,252,232, 0.5)', position: 'absolute' }}/> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
        >
          <Loader />
        </div>
      </>
    )
  }

  return (
    <AuthContext.Provider value={{ ...authCtxUser, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}
