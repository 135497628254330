import { useState } from 'react'
import { InstallationContext } from './InstallationContext'

export const InstallationContainer = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [installationId, setInstallationId] = useState('')
  const [installations, setInstallations] = useState<{
    [id: string]: {
      rewardsEnabled: boolean
      points: number
      birthdayPoints: number
      anniversaryPoints: number
      userCount: number
    }
  }>({})
  const [role, setRole] = useState<'member' | 'admin'>('member')

  return (
    <InstallationContext.Provider
      value={{
        id: installationId,
        installations,
        setInstallations,
        role: role,
        setRole: setRole,
        set: setInstallationId
      }}
    >
      {children}
    </InstallationContext.Provider>
  )
}
