import visa from './visa.png'
import mastercard from './mastercard.png'

export const PaymentBrand = ({ name }: { name: string }) => {
  const map: Record<string, string> = {
    visa: visa,
    mastercard: mastercard
  }
  const mapped = map[name]
  return mapped ? (
    <img src={mapped} width={50} height={30} alt={name} />
  ) : (
    <strong>name</strong>
  )
}
