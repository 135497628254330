import { Row, Col, Card, Button } from 'react-bootstrap'
import logo from '../background1.jpg'
import { Input, Loader, Select } from '@mantine/core'
import useAxios from 'axios-hooks'
import { useEffect, useState } from 'react'
import { SPagination } from '../common/Pagination'

// TODO: move installations to track in context and use in offcanvas menu too

// TODO: enhance backend timeline call and enhance card with product, message, etc etc

type Timeline = {
  feedbackId: string
  createdAt: string
  feedbackImageUrl: string
  feedbackMessage: string
  kudosType: string
  senderDisplayName: string
}

const pageSize = 20

export const Timeline = ({
  installationId,
  installationsLoading
}: {
  installationId?: string
  installationsLoading: boolean
}) => {
  const [activePage, setPage] = useState(0)

  const [query, setQuery] = useState('')
  const [search, setSearch] = useState('')
  const [kudosType, setKudosType] = useState<string | null>(null)

  const [{ data, loading: timelineLoading }] = useAxios<{
    data: Timeline[]
    total: number
  }>(
    {
      url: `account/installations/${installationId}/feedback`,
      params: {
        pageSize,
        page: activePage,
        ...(query && { query }),
        ...(kudosType && { kudosType })
      }
    },
    {
      manual: !installationId
    }
  )

  const timeline = data?.data
  const total = data?.total

  // TODO: add installation loading and call to action to install if no installations found
  return (
    <>
      <Row className="justify-content-sm-center">
        <Col className="col-sm-12">
          <Card
            style={{
              flexDirection: 'row',
              border: 'none',
              boxShadow:
                '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 0.625rem 0.9375rem -0.3125rem, rgba(0, 0, 0, 0.04) 0 0.4375rem 0.4375rem -0.3125rem'
            }}
          >
            <Card.Img
              variant="left"
              src={logo}
              style={{
                height: 200,
                objectFit: 'contain',
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5
              }}
            />
            <Card.Body>
              <Card.Title>
                Welcome to slashSend - Send Feedback and Rewards on Slack
              </Card.Title>
              <Card.Text>
                As you and your team send kudos to eachother using the{' '}
                <code>/send</code> command in slack a timeline will appear here.
              </Card.Text>
              {!installationId && !installationsLoading && (
                <Button
                  className="learn-ssend"
                  href="https://slack.com/oauth/v2/authorize?scope=chat%3Awrite%2Cchat%3Awrite.public%2Ccommands%2Cincoming-webhook%2Cusers%3Aread%2Cusers%3Aread.email&client_id=2544464690679.2552641217286"
                >
                  install on slack
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col className="col-md-9">
          <Input.Wrapper label="Search past feedback">
            <Input
              onChange={(e) => {
                setSearch(e.currentTarget.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                  setQuery(search)
                }
              }}
              placeholder="e.g. 'awesome job'"
            />
          </Input.Wrapper>
        </Col>
        <Col className="col-md-3">
          <Input.Wrapper label="Kudos type">
            <Select
              allowDeselect
              onChange={(e) => {
                setKudosType(e)
              }}
              data={[
                {
                  label: 'Amazing job',
                  value: 'amazing_job'
                },
                {
                  label: 'Thank you',
                  value: 'thank_you'
                },
                {
                  label: 'Well done',
                  value: 'well_done'
                },
                {
                  label: 'Impressive',
                  value: 'impressive'
                },
                {
                  label: 'Team player',
                  value: 'team_player'
                },
                {
                  label: 'Legend',
                  value: 'legend'
                }
              ]}
            ></Select>
          </Input.Wrapper>
        </Col>
      </Row>
      {installationsLoading || timelineLoading ? (
        <div
          style={{
            height: 200,
            width: '100%',
            transform: 'translate(50%, 50%)'
          }}
        >
          <Loader />
        </div>
      ) : timeline?.length && timeline?.length > 0 ? (
        timeline.map((a) => (
          <Row
            key={a.feedbackId}
            style={{ marginTop: 50, paddingLeft: 100, paddingRight: 100 }}
          >
            <Col
              className="col-sm-12"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Card
                style={{
                  maxWidth: 650,
                  border: 'none',
                  boxShadow:
                    '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 0.625rem 0.9375rem -0.3125rem, rgba(0, 0, 0, 0.04) 0 0.4375rem 0.4375rem -0.3125rem'
                }}
              >
                <Card.Body>
                  <Card.Title>Kudos received</Card.Title>
                  <Card.Text>
                    {a.senderDisplayName} sent you kudos -{' '}
                    {new Date(a.createdAt).toTimeString().substring(0, 8)}{' '}
                    {new Date(a.createdAt).toLocaleDateString()}
                  </Card.Text>
                </Card.Body>
                <Card.Img variant="bottom" src={a.feedbackImageUrl} />
              </Card>
            </Col>
          </Row>
        ))
      ) : (
        <Row
          style={{ padding: 50, alignContent: 'center', textAlign: 'center' }}
        >
          <Col className="col-sm-12">
            Just <code>/send</code> to make your teammates feel awesome 🦕🥳
          </Col>
        </Row>
      )}
      <SPagination
        activePage={activePage}
        setPage={setPage}
        total={total}
        pageSize={pageSize}
      />
      {timeline && timeline.length > 0 && (
        <Row
          style={{ padding: 50, alignContent: 'center', textAlign: 'center' }}
        >
          <Col className="col-sm-12">
            Just <code>/send</code> to keep your teammates feeling awesome 🦕🥳
          </Col>
        </Row>
      )}
    </>
  )
}
