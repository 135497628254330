import {
  Autocomplete,
  Avatar,
  Card,
  Col,
  Grid,
  Group,
  Image,
  Loader,
  Button,
  Modal,
  NumberInput,
  SelectItemProps,
  Text,
  SegmentedControl,
  Tooltip
} from '@mantine/core'
import useAxios from 'axios-hooks'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useInstallationContext } from '../InstallationContext/InstallationContext'
import { OrgName } from '../common/OrgName'
import { useDebounce } from 'use-debounce'
import Confetti from 'react-confetti'
import { SPagination } from '../common/Pagination'
import country from 'country-code-lookup'
import ReactCountryFlag from 'react-country-flag'

const PBP_API_KEY = process.env.REACT_APP_PBP_API_KEY!

export const RewardPage = () => {
  const installationContext = useInstallationContext()
  const [value, setValue] = useState('')
  const [searchValue, setSearchValue] = useState('')

  const [pointsValue, setPointsValue] = useState(0)

  const [debouncedValue] = useDebounce(value, 500)

  const [activePage, setPage] = useState(0)

  const [{ loading: pointsLoading, data: pointsData }, getPoints] = useAxios<{
    data: { pointsToRedeem: number }
  }>({
    url: `account/installations/${installationContext?.id}/rewards/points`,
    method: 'GET'
  })

  useEffect(() => {
    setPointsValue(pointsData?.data?.pointsToRedeem || 0)
  }, [pointsData?.data?.pointsToRedeem])

  const pageSize = 10

  const [{ loading: nonprofitsLoading, data: nonprofitsDataFromApi }] =
    useAxios({
      baseURL: 'https://api.poweredbypercent.com/v1/',
      url: `organisations`,
      params: {
        query: searchValue,
        pageSize,
        page: activePage + 1,
        category: ['category_000000CkTjRRINjK5gDTFHLWS7nWq']
      },
      method: 'GET',
      headers: {
        Authorization: PBP_API_KEY
      }
    })

  const [nonprofitsData, setNonprofitsData] = useState<any>([])

  useEffect(() => {
    setNonprofitsData(nonprofitsDataFromApi)
  }, [JSON.stringify(nonprofitsDataFromApi || {})])

  const [
    { loading: nonprofitsAutocompleLoading, data: nonprofitsAutocompleData }
  ] = useAxios({
    baseURL: 'https://api.poweredbypercent.com/v1/',
    url: `organisations`,
    params: {
      query: debouncedValue,
      pageSize: 5,
      category: ['category_000000CkTjRRINjK5gDTFHLWS7nWq']
    },
    method: 'GET',
    headers: {
      Authorization: PBP_API_KEY
    }
  })

  const data =
    nonprofitsData?.data?.filter((a: any) => a.name === value)[0] || null
  const total = nonprofitsData?.totalResults

  const handleChange = (value: string) => {
    setValue(value)
  }

  const [redeemOpen, setRedeemOpen] = useState('')

  const [
    { loading: redeemRewardLoading, data: redeemRewardData },
    redeemReward
  ] = useAxios(
    {
      url: `rewards/redeem-request`,
      data: {
        rewardType: 'donation',
        points: pointsValue,
        organisationId: redeemOpen,
        installationId: installationContext?.id,
        description: `Donation to ${nonprofitsData?.data?.find((a: any) => a.id === redeemOpen)?.name}`
      },
      method: 'POST'
    },
    {
      manual: true
    }
  )

  const [showConfetti, setShowConfetti] = useState(false)

  useEffect(() => {
    if (showConfetti) {
      setTimeout(() => {
        setRedeemOpen('')
      }, 2000)
      setTimeout(() => {
        setShowConfetti(false)
      }, 5000)
    }
  }, [showConfetti])

  interface ItemProps extends SelectItemProps {
    description: string
    logo: string
    name: string
    id: string
    website: string
  }

  const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    (
      { name, description, value, logo, id, website, ...others }: ItemProps,
      ref
    ) => {
      return (
        <div ref={ref} {...others} key={id} style={{ width: '98%' }}>
          <Group noWrap style={{ display: '-webkit-box' }}>
            {logo ? (
              <Avatar src={logo} />
            ) : (
              <OrgName
                name={name}
                styles={{
                  width: 38,
                  borderRadius: 4,
                  height: 38,
                  marginRight: '10px',
                  fontSize: 20
                }}
                onClick={() => {}}
              />
            )}
            <div>
              <Text>{name}</Text>
              <Text
                size="xs"
                color="dimmed"
                style={{ wordBreak: 'keep-all', width: '90%' }}
              >
                {description}
              </Text>
            </div>
          </Group>
        </div>
      )
    }
  )

  if (!installationContext?.id) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <Loader />
      </div>
    )
  }

  return (
    <div>
      {showConfetti && <Confetti numberOfPieces={1000} recycle={false} tweenDuration={20000} style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 2147483647
      }} />}
      <h1>Redeem Reward Points</h1>
      <Tooltip
        style={{ marginLeft: 0 }}
        position="right"
        label="Gift cards coming soon"
      >
        <SegmentedControl
          data={['Gift cards', 'Donations']}
          value="Donations"
        />
      </Tooltip>
      <div>
        You earn points as your team mates award them to you when giving Kudos.
        You can give points to your team mates too.
      </div>
      {pointsLoading ? <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}><Loader /></div> :       <div>
        Points to spend: {pointsData?.data?.pointsToRedeem || 0}{' '}
        {pointsData?.data?.pointsToRedeem
          ? `= £${pointsData?.data?.pointsToRedeem}`
          : ''}
      </div>}
      <Autocomplete
        value={value}
        itemComponent={AutoCompleteItem}
        data={
          nonprofitsAutocompleData?.data
            ?.map((a: any) => ({ ...a, value: a.name }))
            .filter((_: any, i: number) => i < 5) || []
        }
        filter={(_) => true}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            setSearchValue(e.currentTarget.value)
            e.currentTarget.blur()
          }
        }}
        onItemSubmit={(data) => {
          setNonprofitsData({ data: [data] })
        }}
        onChange={handleChange}
        rightSection={
          nonprofitsAutocompleLoading ? <Loader size="1rem" /> : null
        }
        label="Search nonprofits to donate to using your points"
        placeholder="e.g. Oxfam"
      />
      {nonprofitsLoading ? (
        <div
          style={{
            height: '50vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Loader />
        </div>
      ) : (
        <Grid
          style={{
            margin: 20,
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: '1rem 3rem',
            gridTemplateRows: 'masonry',
            display: 'grid'
          }}
        >
          {nonprofitsData?.data?.map((data: any) => (
            <Grid.Col span={12}>
              <Card shadow="sm" padding="lg" radius="md">
                <Card.Section>
                  {data.logo ? (
                    <Image
                      src={data.logo}
                      alt={data.name}
                      height={200}
                      imageProps={{ style: { objectFit: 'contain' } }}
                    />
                  ) : (
                    <OrgName
                      name={data.name}
                      styles={{
                        pointerEvents: 'none'
                      }}
                      onClick={() => {}}
                    />
                  )}
                </Card.Section>
                <h3>
                  {data.name}
                  <br />
                  {country.byIso(data.countryCode)?.iso2 ? (
                    <ReactCountryFlag
                      countryCode={country.byIso(data.countryCode)?.iso2!}
                    />
                  ) : null}
                </h3>
                {data.website && (
                  <a
                    href={data.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.website}
                  </a>
                )}
                <p>{data.description}</p>
                <Group>
                  <Button
                    disabled={
                      redeemRewardLoading ||
                      pointsLoading ||
                      pointsData?.data?.pointsToRedeem === 0
                    }
                    onClick={() => {
                      setRedeemOpen(data.id)
                    }}
                  >
                    Donate
                  </Button>
                </Group>
              </Card>
            </Grid.Col>
          ))}
        </Grid>
      )}
      <Modal
        opened={!!redeemOpen}
        onClose={() => setRedeemOpen('')}
        title="Redeem points"
      >
        {redeemRewardLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader /></div>
        ) : showConfetti ?
          <p>Thank you for your donation 🎉</p>
        : (
          <>
            <p>
              How many points would you like to spend to donate
              {nonprofitsData?.data?.find(({ id }: any) => id === redeemOpen)
                ? ` to ${nonprofitsData?.data?.find(
                    ({ id }: any) => id === redeemOpen
                  ).name}`
                : ''}
              ?
            </p>
            <NumberInput
              style={{ width: 90, marginRight: 20 }}
              min={1}
              max={pointsData?.data?.pointsToRedeem || 0}
              value={pointsValue}
              disabled={
                redeemRewardLoading ||
                pointsLoading ||
                pointsData?.data?.pointsToRedeem === 0
              }
              onChange={(pointsValue) => {
                setPointsValue(pointsValue || 0)
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 20
              }}
            >
              <Button
                color="gray"
                onClick={() => {
                  setRedeemOpen('')
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  await redeemReward()
                  setShowConfetti(true)
                  getPoints()
                }}
              >
                Donate £{pointsValue}
              </Button>
            </div>
          </>
        )}
      </Modal>
      {nonprofitsLoading ? null : (
        <SPagination
          activePage={activePage}
          setPage={setPage}
          total={total > 500 ? 500 : total}
          pageSize={pageSize}
        />
      )}
    </div>
  )
}
