import React, { useState, useEffect } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap'
import { createPaymentIntent, OrderDetails, Product } from '../slashsendClient'
import { Loader } from '@mantine/core'

export const CheckoutForm = ({ token }: { token: string }) => {
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [piError, setPiError] = useState('')
  const [processing, setProcessing] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [clientSecret, setClientSecret] = useState('')
  const [product, setProduct] = useState<Product>()
  const [orderDetails, setOrderDetails] = useState<OrderDetails>()
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (token?.length > 0) {
      createPaymentIntent(token)
        .then(({ clientSecret, product, orderDetails }) => {
          setLoading(false)
          setClientSecret(clientSecret)
          setProduct(product)
          setOrderDetails(orderDetails)
        })
        .catch((e) => {
          console.error(e)
          const errorMsg = e?.response?.data?.message
          setLoading(false)
          if (errorMsg === 'Order has already been fulfilled') {
            setSucceeded(true)
          } else {
            setPiError(errorMsg || 'something went wrong')
          }
        })
    }
  }, [token])

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Roboto, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }
  }

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty)
    setError(event.error ? event.error.message : '')
  }

  const handleSubmit = async (ev: any) => {
    ev.preventDefault()
    setProcessing(true as any)
    const payload = await stripe!.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements!.getElement(CardElement)!
      }
    })
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}` as any)
      setProcessing(false as any)
    } else {
      setError(null)
      setProcessing(false as any)
      setSucceeded(true)
    }
  }

  if (loading) {
    return <Loader />
  }

  if (piError) {
    return (
      <Card.Title
        style={{
          padding: 20
        }}
      >
        Oops. {piError}. Sorry for that, please try again.
      </Card.Title>
    )
  }

  if (succeeded) {
    return (
      <>
        <Card.Title>Payment succeeded</Card.Title>
        <Card.Text>
          You will receive a receipt and your reward will be delivered shortly.
        </Card.Text>
        <Button href="https://slashsend.io/" variant="primary">
          Learn about slashSend
        </Button>
      </>
    )
  }

  return (
    <>
      <p
        className="slash-send"
        style={{
          fontSize: 30,
          textAlign: 'center',
          fontWeight: 'bold',
          font: 'Source Code Pro, monospace'
        }}
      >
        <a href="https://slashsend.io" target="_blank" rel="noreferrer">
          /slashSend
        </a>
      </p>

      <Card.Title
        style={{
          padding: 20
        }}
      >
        Get a&nbsp;£{(product?.price! / 100).toFixed(2)}&nbsp;
        <span
          style={{
            backgroundColor: '#ff91c178'
          }}
        >
          {product?.name}
        </span>
        &nbsp;for&nbsp;
        <span
          style={{
            backgroundColor: 'rgba(242,199,68,.4)'
          }}
        >
          @{orderDetails?.recipientUserName}
        </span>
        , they will appreciate it 🙏
      </Card.Title>
      <Card.Text>
        <Row>
          <Col>
            <form id="payment-form" onSubmit={handleSubmit}>
              <div
                style={{
                  maxWidth: 400,
                  display: 'flex',
                  flexDirection: 'column',
                  margin: 'auto'
                }}
              >
                <div style={{ display: 'flex' }}>
                  <img
                    style={{
                      height: 60,
                      width: 60,
                      borderRadius: 4
                    }}
                    src={product?.image}
                    alt={`${product?.name} logo`}
                  />
                  <div
                    style={{
                      marginLeft: 15,
                      marginTop: -3
                    }}
                  >
                    <p
                      style={{
                        textAlign: 'left',
                        margin: 0
                      }}
                    >
                      {product?.name}
                    </p>
                    <p
                      style={{
                        fontSize: 30,
                        textAlign: 'left'
                      }}
                    >
                      £{(product?.price! / 100).toFixed(2)}
                    </p>
                  </div>
                </div>
                <CardElement
                  id="card-element"
                  options={cardStyle}
                  onChange={handleChange}
                />
                <Button
                  disabled={!!processing || disabled || succeeded}
                  id="submit"
                  style={{
                    width: '100%',
                    marginTop: 20
                  }}
                  type="submit"
                >
                  <span id="button-text">
                    {processing ? <Loader /> : 'Pay now'}
                  </span>
                </Button>
              </div>
              {/* Show any error that happens when processing the payment */}
              {error && (
                <div className="card-error" role="alert">
                  {error}
                </div>
              )}
              {/* Show a success message upon completion */}
            </form>
          </Col>
        </Row>
      </Card.Text>
    </>
  )
}
