import {
  Loader,
  Divider,
  NumberInput,
  SegmentedControl,
  Modal,
  Group,
  Button,
  Popover,
  Card
} from '@mantine/core'
import { PaymentBrand } from '../Home/PaymentBrand'
import { useInstallationContext } from '../InstallationContext/InstallationContext'
import useAxios from 'axios-hooks'
import { useState, useEffect } from 'react'

export const AdminPanel = () => {
  const installationContext = useInstallationContext()

  const {
    rewardsEnabled,
    points: p,
    birthdayPoints,
    anniversaryPoints,
    userCount
  } = installationContext?.installations?.[installationContext.id] || {}

  const points = p ?? 0

  const teamMemberCount = userCount || 1

  const [pointsValue, setPointsValue] = useState(5)

  const [editPointsValue, setEditPointsValue] = useState(points || 0)

  useEffect(() => {
    setEditPointsValue(points)
  }, [points])

  const [
    { loading: disableRewardsLoading, data: disableRewardsData },
    disableRewards
  ] = useAxios<{ success: true }>(
    {
      url: `installations/${installationContext.id}/team-admin/disable-rewards`,
      data: {
        installationId: installationContext.id
      },
      method: 'POST'
    },
    {
      manual: true
    }
  )

  const [
    { loading: enableRewardsLoading, data: enableRewardsData },
    enableRewards
  ] = useAxios<{ success: true }>(
    {
      url: `installations/${installationContext.id}/team-admin/enable-rewards`,
      data: {
        installationId: installationContext.id,
        points: pointsValue
      },
      method: 'POST'
    },
    {
      manual: true
    }
  )

  const [
    { loading: updatePointsLoading, data: updatePointsData },
    updatePoints
  ] = useAxios<{ success: true }>(
    {
      url: `installations/${installationContext.id}/team-admin/update-points`,
      data: {
        installationId: installationContext.id,
        points: editPointsValue
      },
      method: 'POST'
    },
    {
      manual: true
    }
  )

  const [
    { loading: addPaymentMethodLoading, data: addPaymentMethodData },
    addPaymentMethod
  ] = useAxios(
    {
      url: `installations/${installationContext.id}/team-admin/add-payment-method`,
      data: {
        installationId: installationContext.id
      },
      method: 'POST'
    },
    {
      manual: true
    }
  )

  const [
    { loading: getPaymentMethodLoading, data: getPaymentMethodData },
    getPaymentMethod
  ] = useAxios(
    {
      url: `installations/${installationContext.id}/team-admin/payment-methods`,
      data: {
        installationId: installationContext.id
      },
      method: 'GET'
    },
    {
      manual: installationContext?.id ? false : true
    }
  )

  const [
    { loading: removePaymentMethodLoading, data: removePaymentMethodData },
    removePaymentMethod
  ] = useAxios(
    {
      url: `installations/${installationContext.id}/team-admin/remove-payment-method`,
      data: {
        installationId: installationContext.id
      },
      method: 'POST'
    },
    {
      manual: true
    }
  )

  const [
    { loading: birthdayPromptLoading, data: birthdayPromptResponseDate },
    promptBirthday
  ] = useAxios(
    {
      url: `installations/${installationContext.id}/team-admin/prompt-birthday`,
      data: {
        installationId: installationContext.id
      },
      method: 'POST'
    },
    {
      manual: true
    }
  )

  const [
    { loading: anniversaryPromptLoading, data: anniversaryPromptReponseData },
    promptAnniversary
  ] = useAxios(
    {
      url: `installations/${installationContext.id}/team-admin/prompt-anniversary`,
      data: {
        installationId: installationContext.id
      },
      method: 'POST'
    },
    {
      manual: true
    }
  )

  const [birthdayPrompted, setBirthdayPrompted] = useState(false)
  const [anniversaryPrompted, setAnniversaryPrompted] = useState(false)

  useEffect(() => {
    if (sessionStorage.getItem(`${installationContext.id}-birthday-prompted`)) {
      setBirthdayPrompted(true)
    }
    if (
      sessionStorage.getItem(`${installationContext.id}-anniversary-prompted`)
    ) {
      setAnniversaryPrompted(true)
    }
  }, [installationContext.id])

  const [showRewardModal, toggleRewardModal] = useState(false)
  const [showEditPointsModal, toggleEditPointsModal] = useState(false)

  const [showPaymentMethodRequiredPopover, togglePaymentMethodRequiredPopover] =
    useState(false)

  return (
    <Card style={{ padding: 30 }}>
      {addPaymentMethodLoading ? (
        <Loader type="bars" />
      ) : (
        <>
          <h4>Team admin</h4>
          <Divider my="xs" size="sm" />
          <div>
            <h5>Birthday and team anniversary cards</h5>
            <p>
              You can send a prompt to your team members to set their birthday
              and work anniversary.
              <br />
              Once set a card will be sent to them through the team channel so
              you all can celebrate their special day together.
            </p>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              Prompt team members to set their birthday
              <Button
                disabled={
                  birthdayPromptLoading ||
                  birthdayPromptResponseDate ||
                  birthdayPrompted
                }
                style={{
                  width: 215
                }}
                onClick={async () => {
                  await promptBirthday()
                  sessionStorage.setItem(
                    `${installationContext.id}-birthday-prompted`,
                    'true'
                  )
                }}
              >
                {birthdayPromptResponseDate ? (
                  'Prompt sent'
                ) : birthdayPromptLoading ? (
                  <Loader color="white" size={25} />
                ) : (
                  'Send prompt via slack'
                )}
              </Button>
            </div>
            <Divider my="xs" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              Prompt team members to set their work anniversary
              <Button
                style={{
                  width: 215
                }}
                disabled={
                  anniversaryPromptLoading ||
                  anniversaryPromptReponseData ||
                  anniversaryPrompted
                }
                onClick={async () => {
                  await promptAnniversary()
                  sessionStorage.setItem(
                    `${installationContext.id}-anniversary-prompted`,
                    'true'
                  )
                }}
              >
                {anniversaryPromptReponseData ? (
                  'Prompt sent'
                ) : anniversaryPromptLoading ? (
                  <Loader color="white" size={25} />
                ) : (
                  'Send prompt via slack'
                )}
              </Button>
            </div>
          </div>
          <Divider my="xs" size="sm" />
          <div>
            <h5>Payment Method</h5>
            {getPaymentMethodLoading ? (
              <Loader />
            ) : getPaymentMethodData?.data?.length === 0 ? (
              'No payment method set.'
            ) : (
              <div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <p>Current payment method</p>
                    <PaymentBrand
                      name={getPaymentMethodData?.data?.[0].brand}
                    />{' '}
                    ending in{' '}
                    <code>{getPaymentMethodData?.data?.[0].last4}</code>
                  </div>
                  <Button
                    color="red"
                    onClick={async () => {
                      await removePaymentMethod()
                      await getPaymentMethod()
                      installationContext.setInstallations({
                        ...installationContext.installations,
                        [installationContext.id]: {
                          ...installationContext.installations[
                            installationContext.id
                          ],
                          rewardsEnabled: false
                        }
                      })
                    }}
                    disabled={removePaymentMethodLoading}
                  >
                    {removePaymentMethodLoading ? (
                      <Loader color="white" size={25} />
                    ) : (
                      'Remove'
                    )}
                  </Button>
                </div>
              </div>
            )}
            {getPaymentMethodData?.data?.length < 1 ? (
              <>
                <p>To enable rewards you need to add a payment method.</p>
                <Button
                  disabled={addPaymentMethodLoading}
                  onClick={async () => {
                    const { data } = await addPaymentMethod()
                    window.location.href = data.url
                  }}
                >
                  Add a payment method
                </Button>
              </>
            ) : null}
            <div></div>
          </div>
          <Divider my="xs" size="sm" />
          <div>
            <h5>Rewards</h5>
            <p>
              Rewards allow your team members to reward each other points, at
              the start of every month each team member is given a monthly
              budget of points. If the points are not used then they will not
              rollover to the next month. They cannot redeem these points but
              they can give them to other team members, once received the team
              member can redeem them for a reward of a gift card or a donation
              from the reward catalogue. Billing occurs on redemption to your
              payment method.
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Modal.Root
                opened={showRewardModal}
                onClose={() => toggleRewardModal(false)}
              >
                <Modal.Overlay />
                <Modal.Content>
                  <Modal.Header>
                    <Modal.Title>
                      {rewardsEnabled ? 'Disable rewards' : 'Enable rewards'}
                    </Modal.Title>
                    <Modal.CloseButton></Modal.CloseButton>
                  </Modal.Header>
                  <Modal.Body>
                    {enableRewardsLoading || disableRewardsLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {rewardsEnabled ? (
                          'Are you sure you want to disable rewards? This will stop your team members from being able to give each other reward points.'
                        ) : (
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'baseline',
                                justifyContent: 'space-between'
                              }}
                            >
                              <p>Set initial points</p>
                              <NumberInput
                                style={{ width: 90, marginRight: 20 }}
                                min={0}
                                max={1000}
                                value={pointsValue}
                                onChange={(value) => {
                                  setPointsValue(value || 0)
                                }}
                              />
                            </div>
                            <p>
                              Approximate maximum cost: {pointsValue} points *{' '}
                              {teamMemberCount} team members = £
                              {(pointsValue * teamMemberCount * 1.03).toFixed(
                                2
                              )}
                            </p>
                            <p>
                              Billing only occurs on reward redemption, you will
                              not be billed for unused points.
                            </p>
                          </div>
                        )}
                        Are you sure you want to{' '}
                        {rewardsEnabled ? 'disable' : 'enable'} rewards?
                        <Group
                          style={{
                            justifyContent: 'space-between',
                            marginTop: 30
                          }}
                        >
                          <Button
                            disabled={
                              enableRewardsLoading || disableRewardsLoading
                            }
                            onClick={() => {
                              toggleRewardModal(false)
                            }}
                            color="gray"
                          >
                            No
                          </Button>
                          <Button
                            disabled={
                              pointsValue < 1 ||
                              enableRewardsLoading ||
                              disableRewardsLoading
                            }
                            onClick={
                              !rewardsEnabled
                                ? async (_) => {
                                    await enableRewards()
                                    toggleRewardModal(false)
                                    installationContext.setInstallations({
                                      ...installationContext.installations,
                                      [installationContext.id]: {
                                        ...installationContext.installations[
                                          installationContext.id
                                        ],
                                        rewardsEnabled: true,
                                        points: pointsValue
                                      }
                                    })
                                  }
                                : async () => {
                                    disableRewards()
                                    installationContext.setInstallations({
                                      ...installationContext.installations,
                                      [installationContext.id]: {
                                        ...installationContext.installations[
                                          installationContext.id
                                        ],
                                        rewardsEnabled: false
                                      }
                                    })
                                    toggleRewardModal(false)
                                  }
                            }
                          >
                            Yes
                          </Button>
                        </Group>
                      </>
                    )}
                  </Modal.Body>
                </Modal.Content>
              </Modal.Root>
              <Popover
                opened={showPaymentMethodRequiredPopover}
                onChange={togglePaymentMethodRequiredPopover}
              >
                <Popover.Target>
                  <SegmentedControl
                    data={['on', 'off']}
                    value={rewardsEnabled ? 'on' : 'off'}
                    onClick={() => {
                      if (getPaymentMethodData?.data?.length < 1) {
                        togglePaymentMethodRequiredPopover(true)
                        return
                      }
                      toggleRewardModal(true)
                    }}
                  />
                </Popover.Target>

                <Popover.Dropdown>
                  <p>To enable rewards first add a payment method</p>
                </Popover.Dropdown>
              </Popover>
            </div>
            {/* <p>
      TODO: disable members
    </p> */}
            <Divider my="xs" />
            <Modal.Root
              opened={showEditPointsModal}
              onClose={() => toggleEditPointsModal(false)}
            >
              <Modal.Overlay />
              <Modal.Content>
                <Modal.Header>
                  <Modal.Title>Edit points</Modal.Title>
                  <Modal.CloseButton></Modal.CloseButton>
                </Modal.Header>
                <Modal.Body>
                  <NumberInput
                    style={{ width: 90, marginRight: 20 }}
                    min={0}
                    max={1000}
                    value={editPointsValue}
                    onChange={(value) => {
                      setEditPointsValue(value || 0)
                    }}
                  />
                  <div>
                    <p>
                      Approximate maximum cost: {editPointsValue} points *{' '}
                      {teamMemberCount} team members = £
                      {(editPointsValue * teamMemberCount * 1.03).toFixed(2)}
                    </p>
                    <p>
                      Billing only occurs on reward redemption, you will not be
                      billed for unused points.
                    </p>
                  </div>
                  Are you sure you want to edit points?
                  <Group
                    style={{
                      justifyContent: 'space-between',
                      marginTop: 30
                    }}
                  >
                    <Button
                      disabled={updatePointsLoading}
                      onClick={() => {
                        toggleEditPointsModal(false)
                      }}
                      color="gray"
                    >
                      No
                    </Button>
                    <Button
                      disabled={pointsValue < 1 || updatePointsLoading}
                      onClick={async (_) => {
                        await updatePoints()
                        toggleEditPointsModal(false)
                        installationContext.setInstallations({
                          ...installationContext.installations,
                          [installationContext.id]: {
                            ...installationContext.installations[
                              installationContext.id
                            ],
                            points: editPointsValue
                          }
                        })
                      }}
                    >
                      Yes
                    </Button>
                  </Group>
                </Modal.Body>
              </Modal.Content>
            </Modal.Root>
            <div>
              <p>
                How many points does each team member get per month to give out?
                If the points are not given out they will not rollover to the
                next month.
              </p>
              <div
                style={{
                  display: 'flex'
                }}
              >
                <NumberInput
                  style={{ width: 60, marginRight: 20, pointerEvents: 'none' }}
                  value={points}
                  hideControls={true}
                  readOnly
                />
                <Button
                  disabled={!rewardsEnabled}
                  onClick={() => {
                    toggleEditPointsModal(true)
                  }}
                >
                  Edit
                </Button>
              </div>
              <p>
                <p>
                  Approximate maximum cost: {points} points * {teamMemberCount}{' '}
                  team members = £{(points * teamMemberCount * 1.03).toFixed(2)}
                </p>
              </p>
              {
                // TODO
                /* <Divider my="xs" />
    <p>
      Birthday points reward
    </p>
    <div style={{
      display: 'flex'
    }}>
      <NumberInput
        style={{ width: 60, marginRight: 20, pointerEvents: 'none' }}
        value={birthdayPoints}
        hideControls={true}
        readOnly
      />
      <Button
        disabled={!rewardsEnabled}
      >
        Edit
      </Button>
    </div>
    <Divider my="xs" />
    <p>
      Anniversary points reward
    </p>
    <div style={{
      display: 'flex'
    }}>
      <NumberInput
        style={{ width: 60, marginRight: 20, pointerEvents: 'none' }}
        value={anniversaryPoints}
        hideControls={true}
        readOnly
      />
      <Button
        disabled={!rewardsEnabled}
      >
        Edit
      </Button>
    </div> */
              }
            </div>
          </div>
        </>
      )}
    </Card>
  )
}
