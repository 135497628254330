import axios from 'axios'
import { slashsendUrl } from './config'

export type Product = {
  id: string
  name: string
  currency: string
  price: number
  externalId: string
  type: string
  image?: string
}

export type OrderDetails = {
  recipientUserName: string
  recipientEmail: string
  customerEmail: string
}

const slashsendClient = axios.create({
  baseURL: `${slashsendUrl}v1/`
})

type PostPaymentIntentResponse = {
  data: {
    clientSecret: string
    product: Product
    orderDetails: OrderDetails
  }
  object: 'payment_intent'
}

export const createPaymentIntent = async (token: string) => {
  const { data } = await slashsendClient.post<PostPaymentIntentResponse>(
    'payment-intents',
    { token }
  )
  return data.data
}
