import React, { useState } from 'react'
import {
  Button,
  Container,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas
} from 'react-bootstrap'
import Logo from '../logo.png'
import './TopBar.css'
import { Link, NavLink } from 'react-router-dom'
import { useInstallationContext } from '../InstallationContext/InstallationContext'
import { Tooltip } from '@mantine/core'
import { useAuthContext } from '../AuthContext/AuthContext'

export const TopBar = () => {
  const authContext = useAuthContext()
  const installationContext = useInstallationContext()
  const { rewardsEnabled } =
    installationContext?.installations?.[installationContext.id] || {}
  const [signoutOpen, setSignoutOpen] = useState(false)
  const [show, setShow] = useState(false)
  return (
    <>
      <Navbar
        style={{
          backgroundColor: 'white'
        }}
        expand="md"
      >
        <Container fluid>
          <Nav.Item>
            <Navbar.Toggle
              aria-controls="offcanvasNavbar"
              onClick={() => {
                setShow(true)
              }}
            />
            <Navbar.Brand>
              <Link
                to="/home/timeline"
                style={{
                  color: 'black',
                  fontFamily: 'Source Code pro, monospace',
                  lineHeight: 1.8
                }}
              >
                <img
                  alt=""
                  src={Logo}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                  style={{
                    borderRadius: 5,
                    margin: 5
                  }}
                />{' '}
                /slashSend
              </Link>
            </Navbar.Brand>
          </Nav.Item>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            show={show}
          >
            <Offcanvas.Header closeButton onClick={() => {
              setShow(false)
            }} >
              <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <NavLink
                  to="/home/timeline"
                  onClick={() => {
                    setShow(false)
                  }}
                  style={(isActive) => ({
                    color: isActive ? 'rgb(111, 168, 93)' : 'black',
                    paddingLeft: 15,
                    paddingTop: 10
                  })}
                >
                  Home
                </NavLink>
                <NavLink
                  to="/home/members"
                  onClick={() => {
                    setShow(false)
                  }}
                  style={(isActive) => ({
                    color: isActive ? 'rgb(111, 168, 93)' : 'black',
                    paddingLeft: 15,
                    paddingTop: 10
                  })}
                >
                  Members
                </NavLink>
                {rewardsEnabled ? (
                  <>
                    <NavLink
                      to="/home/rewards"
                      onClick={() => {
                        setShow(false)
                      }}
                      style={(isActive) => ({
                        color: isActive ? 'rgb(111, 168, 93)' : 'black',
                        paddingLeft: 15,
                        paddingTop: 10
                      })}
                    >
                      Redeem Reward Points
                    </NavLink>
                    <NavLink
                      to="/home/rewards-history"
                      onClick={() => {
                        setShow(false)
                      }}
                      style={(isActive) => ({
                        color: isActive ? 'rgb(111, 168, 93)' : 'black',
                        paddingLeft: 15,
                        paddingTop: 10
                      })}
                    >
                      Rewards History
                    </NavLink>
                  </>
                ) : (
                  <Tooltip
                    style={{ marginLeft: -150 }}
                    position="right"
                    label="Not enabled"
                  >
                    <NavLink
                      to="/home/rewards"
                      onClick={(e) => {
                        e.preventDefault()
                        setShow(false)
                      }}
                      style={() => ({
                        color: 'grey',
                        paddingLeft: 15,
                        paddingTop: 10
                      })}
                    >
                      Rewards
                    </NavLink>
                  </Tooltip>
                )}
                <NavLink
                  onClick={() => {
                    setShow(false)
                  }}
                  to="/home/analytics"
                  style={(isActive) => ({
                    color: isActive ? 'rgb(111, 168, 93)' : 'black',
                    paddingLeft: 15,
                    paddingTop: 10
                  })}
                >
                  Analytics
                </NavLink>
                <Nav.Link
                  style={{ color: 'red', marginLeft: 15 }}
                  eventKey="link-1"
                  onClick={(e) => {
                    authContext?.signOut()
                  }}
                >
                  Sign out
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}
