// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAsUoVOAnMfMwReI-rX9lHFLiEynRFSo9c',
  authDomain: 'perky-8afa8.firebaseapp.com',
  projectId: 'perky-8afa8',
  storageBucket: 'perky-8afa8.appspot.com',
  messagingSenderId: '551865092493',
  appId: '1:551865092493:web:a770a1afe6b6b6578e55c4',
  measurementId: 'G-K7SNWR5HCT'
}

// Initialize Firebase
const fbApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(fbApp)
export const fbAuth = getAuth(fbApp)
