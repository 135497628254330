import { Link } from "react-router-dom";

export const Terms = () => (
  <div style={{ margin: 50, maxWidth: 800 }}>

    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw'
    }}>
      <Link to="/login">
        <h2>
          Return to home
        </h2>
      </Link>
    </div>

    <h1>1. Terms</h1>

<p>
  By accessing the website and utilizing the Slack application services of the slashSend (hereinafter referred to as "the App"), you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website and Slack application are protected by applicable copyright and trademark law.
</p>

<h1>2. Use License</h1>

<p>
  Permission is granted to temporarily download one copy of the materials (information or software) on the slashSend's website and Slack application for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not:
</p>

<ul>
  <li>modify or copy the materials;</li>
  <li>use the materials for any commercial purpose or for any public display (commercial or non-commercial);</li>
  <li>attempt to decompile or reverse engineer any software contained in the slashSend;</li>
  <li>remove any copyright or other proprietary notations from the materials;</li>
  <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
</ul>

<p>
  This license shall automatically terminate if you violate any of these restrictions and may be terminated by the slashSend at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession, whether in electronic or printed format.
</p>

<h1>3. Disclaimer</h1>

<p>
  The materials and services provided on the slashSend's website and Slack application are provided on an 'as is' basis. The slashSend makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, the slashSend does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials or services on its website or Slack application or otherwise relating to such materials or on any sites linked to this site.
</p>

<h1>3. Disclaimer</h1>

<p>
  The materials and services provided on the slashSend's website and Slack application are provided on an 'as is' basis. The slashSend makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, the slashSend does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials or services on its website or Slack application or otherwise relating to such materials or on any sites linked to this site.
</p>

<h1>4. Limitations</h1>

<p>
  In no event shall the slashSend or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on the slashSend's website or Slack application, even if the slashSend or an authorized representative has been notified orally or in writing of the possibility of such damage.
</p>

<p>
  INFORMATION YOU MAY RECEIVE VIA THE SERVICES OR SLACK APPLICATION MAY NOT BE RELIED UPON BY YOU FOR PERSONAL, SECURITY, MEDICAL, LEGAL, OR FINANCIAL DECISIONS, AND YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION.
</p>

<p>
  The slashSend MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE SERVICES OR THE SLACK APPLICATION FOR ANY PURPOSE OR THE AVAILABILITY OF ANY NETWORKS OR COMMUNICATIONS LINES OR FUNCTIONING OF ANY MOBILE PHONE OR DEVICE NECESSARY FOR THE SERVICES.
</p>

<p>
  We try to keep the slashSend up, bug-free, and safe, but you use it at your own risk. It is not a replacement for or supplement to your own safety precautions, news alerts, and official advice.
</p>

<h1>5. Accuracy of Materials</h1>

<p>
  The materials appearing on the slashSend's website and Slack application could include technical, typographical, or photographic errors. The slashSend does not warrant that any of the materials on its website or Slack application are accurate, complete, or current. The slashSend may make changes to the materials contained on its website or Slack application at any time without notice. However, the slashSend does not make any commitment to update the materials.
</p>

<h1>6. Links</h1>

<p>
  The slashSend has not reviewed all of the sites linked to its website or Slack application and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by the slashSend of the site. Use of any such linked website or Slack application is at the user's own risk.
</p>

<h1>7. Modifications</h1>

<p>
  The slashSend may revise these terms of service for its website or Slack application at any time without notice. By using this website or Slack application, you are agreeing to be bound by the then current version of these terms of service.
</p>


<h1>7. Modifications</h1>

<p>
  The slashSend may revise these terms of service for its website or Slack application at any time without notice. By using this website or Slack application, you are agreeing to be bound by the then current version of these terms of service.
</p>

<h1>8. Governing Law</h1>

<p>
  These terms and conditions are governed by and construed in accordance with the laws of the United Kingdom, and you irrevocably submit to the exclusive jurisdiction of the courts in this location.
</p>

  </div>
)
